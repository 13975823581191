<template>
  <div id="paymentMethod">
    <h2>支付方式</h2>
    <div class="paymentMethod-container">
      <echarts-com id="paymentMethodChart" :options="options"/>
      <ul>
        <li v-for="item of list" :key="item.name">
          <i :style="{backgroundColor:item.color}"></i>
          <span class="name">{{ item.name }}</span>
          <span class="proportion">{{item.percent}}%</span>
          <span>¥{{ item.value }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {formatRatio} from "@/utils/util";

export default {
  components: {
    echartsCom: () => import("@/components/echartsCom")
  },
  data() {
    return {
      options: {} ,
      list:[]
    }
  },
  created() {
    this.getOptions()
  },
  methods: {
    async getOptions() {
      try {
        let res = await this.$http.post("/home/payment/channel", {
          type: "今日",
        });
        if (res.code === 0) {
          let total = 0

          let data = res.data.map(item=>{
            total += item.payAmount
            return {
              value:item.payAmount,
              name:item.platformType,
              proportion:formatRatio(item.proportion)
            }
          })


          this.$set(this, "options", {
            tooltip: {
              trigger: 'item'
            },
            series: [
              {
                type: 'pie',
                radius: ['70%', '90%'],
                center: ['50%', '50%'],
                avoidLabelOverlap: false,
                itemStyle: {
                  borderWidth: 100
                },
                label: {
                  show: true,
                  position: 'center',
                  color: '#4c4a4a',
                  formatter: (params) => {
                    let flag = true;
                    for (let item of this.list) {
                      if (item.name === params.data.name) {
                        flag = false;
                        Object.assign(item, {
                          color: params.color,
                          name: params.name,
                          value: params.value,
                          percent: params.data.proportion,
                        });
                      }
                    }
                    if (flag) {
                      this.list.push({
                        color: params.color,
                        name: params.name,
                        value: params.value,
                        percent: params.data.proportion,
                      });
                    }
                    return `{total|¥${parseInt(total)}}\n\r{active|运营额}`
                  },
                  rich: {
                    total: {
                      fontSize: "1rem",
                      color: 'white'
                    },
                    active: {
                      fontSize: "0.7rem",
                      color: 'white',
                    },
                  }
                },
                data
              }
            ]
          })
        } else {
          this.$message.error(res.msg);
        }
      } catch {
        this.$message.error("停车场列表获取失败");
      }
    }
  }
}
</script>

<style lang="scss">
#paymentMethod {
  height: 25%;
  margin-bottom: 2rem;

  .paymentMethod-container {
    width: 100%;
    height: calc(100% - 3.87rem);
    display: flex;
    justify-content: space-between;
    align-items: center;

    #paymentMethodChart {
      width: 29%;
      height: 100%;
      background-color: transparent;
    }

    & > ul {
      width: calc(71% - 1.5rem);
      height: 100%;
      overflow: auto;
      &::-webkit-scrollbar{
        width:0;
      }
      li {
        background-color: rgba(56, 155, 158, 0.21);
        padding: 0.69rem 1.38rem;
        color: white;
        font-size: 0.88rem;
        margin-bottom: 0.6rem;
        white-space: nowrap;

        &:last-child {
          margin-bottom: 0;
        }

        i {
          display: inline-block;
          width: 0.88rem;
          height: 0.88rem;
          margin-right: 0.88rem;
        }
        .name{
          display: inline-block;
          width: 5.5rem;
          text-align: right;
        }
        .proportion {
          display: inline-block;
          text-align: right;
          width: 3.5rem;
          margin: 0 0.88rem;
        }
      }
    }
  }
}
</style>
